import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "use-transitions-for-simple-animation"
    }}>{`Use transitions for simple animation`}</h1>
    <Vimeo id={436488181} mdxType="Vimeo" />
    <p>{`Game loops are great for fine-grained control. And when all you need is a
little flourish on user action, that's where transitions shine.`}</p>
    <p>{`No details, just keyframes.`}</p>
    <p>{`Transitions let you animate SVG elements by saying `}<em parentName="p">{`"I want this property to
change to this new value and take this long to do so"`}</em>{`.`}</p>
    <p>{`Start-end keyframes are the simplest. You define the starting position. Start a
transition. Define the end position. D3 figures out the rest. Everything from
calculating the perfect rate of change to match your start and end values and
your duration, to `}<em parentName="p">{`what`}</em>{` to change and handling dropped frames.`}</p>
    <p>{`Quite magical.`}</p>
    <p>{`You can also sequence transitions to create complex keyframe-based animation.
Each new transition definition is like a new keyframe. D3 figures out the rest.`}</p>
    <p>{`Better yet, you can use easing functions to make your animation look more
natural. Make rate of change follow a mathematical curve to create smooth
natural movement.`}</p>
    <p>{`You can read more about the `}<em parentName="p">{`why`}</em>{` of easing functions in Disney's
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/12_basic_principles_of_animation"
      }}>{`12 Basic Principles of Animation`}</a>{`.`}</p>
    <p>{`Bottom line is that it makes your animation feel natural.`}</p>
    <p><em parentName="p">{`How`}</em>{` they work is hard to explain. You can grok part of it in my
`}<a parentName="p" {...{
        "href": "https://swizec.com/blog/silky-smooth-piechart-transitions-react-d3js/swizec/8258"
      }}>{`Custom transition tweens`}</a>{`
article.`}</p>
    <p>{`But don't worry about it. All you have to know is that many easing functions
exist. `}<a parentName="p" {...{
        "href": "https://easings.net/"
      }}>{`easings.net`}</a>{` lists the common ones. D3 implements
everything on that list.`}</p>
    <p>{`Let's try an example: A swipe transition.`}</p>
    <h2 {...{
      "id": "build-a-swipe-transition"
    }}>{`Build a swipe transition`}</h2>
    <p>{`You're going to build a ball that swipes left and right, up and down. The goal is to build a component that handles transitions internally and behaves like any other React component to the outside.`}</p>
    <Vimeo id={436488357} mdxType="Vimeo" />
    <p>{`I've prepared an example CodeSandbox for you here:`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/d3-transition-ball-swipe-hooks-n60lb",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h3 {...{
      "id": "how-it-works"
    }}>{`How it works`}</h3>
    <Vimeo id={436488665} mdxType="Vimeo" />
    <p>{`The key to this transition effect is a 3 step process:`}</p>
    <ol>
      <li parentName="ol">{`Copy props into state - your staging area`}</li>
      <li parentName="ol">{`Run a D3 transition as a side-effect when props change`}</li>
      <li parentName="ol">{`Update state so React knows what's up`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Ball = ({ x }) => {
  const [realX, setRealX] = useState(x)
  const circleRef = useRef()

  useEffect(() => {
    let el = d3.select(circleRef.current)

    el.transition()
      .duration(800)
      .ease(d3.easeBounceOut)
      .attr("cx", x)
      .on("end", () => setRealX(x))
  }, [x])

  return <circle r="10" cx={realX} cy={10} ref={circleRef} />
}
`}</code></pre>
    <p>{`We save the incoming prop into state by default. This triggers a render. You're using state to render the component so any future prop changes `}<em parentName="p">{`won't`}</em>{` render.`}</p>
    <p>{`This is important. It lets you run transitions.`}</p>
    <p>{`When the `}<inlineCode parentName="p">{`x`}</inlineCode>{` prop changes, a `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` runs and starts a D3 transition. We're using the blackbox rendering approach for this part: D3 selects the DOM node and takes over.`}</p>
    <p>{`A D3 transition then handles animation, manipulates DOM directly, and takes care of timing, easing functions, the whole shebang.`}</p>
    <p>{`When our transitions ends, we update React state so React knows what's up. Component re-renders again.`}</p>
    <h2 {...{
      "id": "exercise-add-a-vertical-transition"
    }}>{`Exercise: Add a vertical transition`}</h2>
    <p>{`Now that you know the theory, fork the codesandbox and add a vertical transition. Try to run the vertical transition in parallel with a different easing function.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/d3-transition-ball-swipe-hooks-n60lb?file=/src/Ball.js",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`Look at my solution only after you've given it a shot :)`}</p>
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <Vimeo id={436489211} mdxType="Vimeo" />
    <iframe {...{
      "src": "https://codesandbox.io/embed/d3-transition-ball-swipe-hooks-solved-z8bc4",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`You can transition any attribute you'd like, you can run transitions sequentally, and you can run them in parallel by giving them names.`}</p>
    <p>{`D3 handles all interruptes and tricky math for you ✌️`}</p>
    <p>{`Next we're going to look at combining transitions and game loops for some truly powerful stuff.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      